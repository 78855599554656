//
// projects.scss
//

// project list

.table-white {
  --bs-table-bg: #ffffff !important;
}
.table-warning {
  --bs-table-bg: #fcf0db !important;
}

.project-list-table {
  border-collapse: separate;
  border-spacing: 0 12px;

  tr {
    --bs-table-bg: white;
  }
  .bg-success {
    --bs-table-bg: rgb(199, 255, 208);
  }
  .bg-danger {
    --bs-table-bg: rgb(252, 184, 184);
  }
}
