.border_bottom {
  border-bottom: 2px solid #ddd;
}

.zindex1 {
  z-index: 1500;
}

.react-tel-input .form-control {
  width: 100%;
}

tr {
  .row-actions {
    display: none;
  }

  &:hover {
    .row-actions {
      display: block;
    }
  }
}

.react-datepicker-popper {
  z-index: 99
}

::-webkit-input-placeholder {
    color: #ccc !important;
    opacity: 1;
    font-style: italic;
}